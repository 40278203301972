.nav-link{
    cursor: "pointer",
  }
  .nav-link.active {
    background-color: #f8f9fa; /* Light grey background color for the active link */
    color: #007bff !important; /* Set the text color for the active link */
    border-radius: .3em; /* Optional: Add rounded corners for a nicer look */
    font-weight: bold; /* Increase the font weight for the active link */
    box-shadow: 0 0 .3em rgba(0, 123, 255, 0.3); /* Optional: Add a subtle box shadow for emphasis */
    transition: background-color 0.3s ease; /* Optional: Add a smooth transition effect */
  }
  
  /* Hover effect for non-active links */
  .nav-link:not(.active):hover {
    
    color: #007bff; /* Set the text color for the hovered link */
    transition: background-color 0.3s ease; /* Add a smooth transition effect */
  }  

  
 
  .navbar {
    position: relative;
  }

  /* Add additional styles as needed */
  
  @media (max-width: 991px) {
  
    .overlay-nav {
      position: absolute;
      top: 100%; /* Position the dropdown menu below the navbar */
      left: 0;
       background-color: white;
      z-index: 1000; /* Ensure the dropdown menu appears above other content */
    }
    
}
@media (min-width: 992px) {
  .navbar a {  
    font-weight: 600; /* Increase the font weight of navbar links */
    color: 'darkgrey'; /* Set your desired color here */
    font-size: 1.13em;
    
    
    
  
    /* Additional styles as needed */
  }
  
}
