.services-container {
    margin: 0 auto;
    max-width: 1400px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .service-item {
    background-color: white;
    border-radius: 5%;
    /* Set fixed width */
    width: 22.1%; /* Adjust the width as needed */
    margin: 0 1.5%;
    /* Remove max-width */
    /* max-width: calc(22.1%); */
    box-shadow: 0 16px 32px rgba(0, 0, 0, 0.2);
    margin-top: 5.4%;        
}

  
  .circle-container {
    width: 4.5em;
    height: 4.5em;
    border-radius: 50%;
    background: #3498db;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 7% auto;
  }
  
  .service-item h3 {
    color: #4c4c4c;
    font-size: 1.2em;
    text-align: center;
    margin-right: 5%;
    margin-left: 5%;
  }
  
  .service-item ul {
    color: grey;
    list-style-type: none;
    font-size: 1em;
    margin-right: 5%;
    margin-left: 5%;
    margin-top: 1em;    
    margin-bottom: 1.2em;
    
  }
  

  @media (max-width: 1200px) {
    .service-item {
      flex: 1 1 calc(25%); /* Two items per row on tablets */
      max-width: calc(25%);
    }
  }
  @media (max-width: 1000px) {
    .service-item {
      flex: 1 1 calc(30%); /* Two items per row on tablets */
      max-width: calc(30%);
    }
  }
  @media (max-width: 800px) {
    .service-item {
      flex: 1 1 calc(45%); /* Two items per row on tablets */
      max-width: calc(45%);
    }
  }
  @media (max-width: 600px) {
    .service-item {
      flex: 1 1 calc(60%); /* One item per row on mobile */
      max-width: calc(60%);
    }
  }
  @media (max-width: 500) {
    .service-item {
      flex: 1 1 calc(70%); /* One item per row on mobile */
      max-width: calc(70%);
    }
  }

  @media (max-width: 425px) {
    .service-item {
      flex: 1 1 calc(80%); /* One item per row on mobile */
      max-width: calc(80%);
    }
  }
  