.blue-section {
  max-width: 1400px;

  display: flex;
  align-items: center;
  padding-left: 3.35%;
  padding-right: 3.35%;
  padding-bottom: 6%;
  margin: 0 auto;
  width: 100%;
}

.video-container {
  position: relative;
  width: 61%;
  padding-bottom: 34.3%; /* 16:9 aspect ratio (height/width) */
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.blue-section h2 {
  color: grey;
  font-weight: bold;
  margin-top: 0;
  margin-bottom:2%;
}

.blue-section iframe {
  border-radius: 3.5%;
}

.right-content {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 768px) {
  .right-content {
    width: 100%;    
    justify-content: start;
  }
}
@media (max-width: 768px) {
  .video-container {
    padding-bottom:56.25%; /* 16:9 aspect ratio (height/width) */

    width: 100%;
    
  }
}
@media (max-width: 768px) {
  .blue-section {
    max-width: 1400px;
    display:flex;
    flex-direction: column;
    align-items: start;
    padding-left: 3.35%;
    padding-right: 3.35%;
    padding-bottom: 6%;
    margin: 0 auto;
    width: 100%;
  }
}