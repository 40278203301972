
  
  /* Add these styles to your CSS file */
  .form-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .form-container {
    background: #fff;  
    border-radius: 8px;
    max-height: 90vh; /* Set a maximum height, adjust as needed */
    overflow-y: auto; /* Add a scrollbar if the content exceeds the maximum height */
  }
  
  
  .close-btn {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  /* Add a new style to handle clicks outside the form */
  body.form-open {
    overflow: hidden; /* Prevent scrolling when the form is open */
  }
  
  body.form-open .form-overlay {
    pointer-events: none; /* Allow clicks to pass through the overlay */
  }
  
  body.form-open .form-container {
    pointer-events: all; /* Enable clicks on the form */
  }
  