.logo {      
    font-family: 'Libre Baskerville', serif; /* Set the font family */
  }

  .logo-image {
    width: 2.8em;    
    height: 2.8em;    
    margin-right: .62em;
  }

  .logo-text {    
    font-size: 2.5em;
      font-weight: bold;      
      line-height: 1; /* Adjust this value as needed */  
  }
      .row-container {
        display: flex;
        align-items: center; /* This will vertically center the items */                         
      }
        
      .light-blue {        
        color: #005A9c; /* Light Blue color */        
      }
      .dark-blue {        
        color: #003152; /* Dark Blue color */        
      }
      .text-below {         
        letter-spacing: .23em; /* Adjust the letter spacing as needed */
        margin-left: 6.3em; /* Adjust the margin as needed */
        font-size: .56em; /* Adjust the font size as needed */
        font-family: 'Alice', sans-serif; /* Add the 'Alice' font family or use a fallback font */
        color: #003152; /* Dark Blue color */
        font-weight: 500;                        
      }
    
      @media (max-width: 375px) {
        .logo-text {    
          font-size: 2em;
            font-weight: bold;      
            line-height: 1; /* Adjust this value as needed */  
        }
      }
      @media (max-width: 375px) {
  
        .logo-image {
          width: 2.3em;    
          height: 2.3em;    
          margin-right: .5em;
        }
      }
      @media (max-width: 375px) {
          .text-below {         
        letter-spacing: .23em; /* Adjust the letter spacing as needed */
        margin-left: 5.7em; /* Adjust the margin as needed */
        font-size: 0.47em; /* Adjust the font size as needed */
        font-family: 'Alice', sans-serif; /* Add the 'Alice' font family or use a fallback font */
        color: #003152; /* Dark Blue color */
        font-weight: 500;                        
      }
      }