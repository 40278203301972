.image-section {
    position: relative;
    width: 100%;
    height:  auto;  
  }
  
  .image-section img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.7; /* Adjust the opacity value (0.0 to 1.0) */
  }
  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .image-overlay h1 {
    padding: .35em 3.3%;
    font-size: 3.2em; /* Increased font size */
    margin-bottom: 1.32vw;
    color: #fff; /* White text color */
  text-align: center;
    font-family: 'Libre Baskerville', serif; /* Set the font family */
  }
  /* Add these styles to your CSS file */
  .form-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  body.form-open .form-overlay {
    pointer-events: none; /* Allow clicks to pass through the overlay */
  }
  .form-container {
    background: #fff;  
    border-radius: 8px;    
    overflow-y: auto; /* Add a scrollbar if the content exceeds the maximum height */
  }
  
  
  body.form-open .form-container {
    pointer-events: all; /* Enable clicks on the form */
  }
  

  .btn-primary {
    font-size: 1.13em;
}

@media (max-width: 768px) {
  .image-section {
    
    height:  100vw;  
  }
}

@media (max-width: 375px) {
  .image-overlay h1 {
    padding: .35em 3.3%;
    font-size: 2.5em; /* Increased font size */
    margin-bottom: 1.32vw;
    color: #fff; /* White text color */
  text-align: center;
    font-family: 'Libre Baskerville', serif; /* Set the font family */
  }
}

@media (max-width: 375px) {
  .btn-primary {
    font-size: 1em;
}
}
