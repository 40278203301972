.BlueFooter {
    background-color: #004382;
    color: white;
    /* padding: 50px 20px; Decrease the horizontal padding here */
    padding-top: 2.5em;  
    
  
  }
  /* Target specific text elements in the footer and adjust font size */
  .BlueFooter h3,
  /* .BlueFooter h5, */
  .BlueFooter p,
  .BlueFooter ul,
  .BlueFooter li,
  .BlueFooter span,
  .BlueFooter div {
    font-size: 1em; /* Adjust the font size as needed */  
  }
  
  .BlueFooter h5 {
    font-size: 1.34em
  }
  @media (max-width: 768px) {
    .BlueFooter h3,
    /* .BlueFooter h5, */
    .BlueFooter p,
    .BlueFooter ul,
    .BlueFooter li,
    .BlueFooter span,
    .BlueFooter div {
      font-size: .98em; /* Adjust the font size as needed */  
    }
  }
